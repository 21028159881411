import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get, child, Database, set, onValue, update } from "firebase/database";
import { FirebaseService } from "./FirebaseService";

export class FirebaseDBService {

    db: Database;
    private firebase: FirebaseApp;

    constructor() {
        this.firebase = FirebaseService.getApp();
        this.db = getDatabase();
    }

    async readData<T>(path: string): Promise<T | null> {
        try {
            const snapshot = await get(child(ref(this.db), path));
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                console.log("No data available");
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async writeData<T>(path: string, data: T) {
        try {
            await set(ref(this.db, path), data);
        } catch (error) {
            console.log(error);
        }
    }

    async listenForChanges<T>(path: string, callback: (data: T) => void) {
        const elRef = ref(this.db, path);
        onValue(elRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });
    }

    async updateValues(updates: { [path: string]: any }) {
        update(ref(this.db), updates);
    }
}