
import { defineComponent } from "@vue/runtime-core";
import ActivityButton from "./ActivityButton.vue";
import { WeatherData } from "../model/WeatherData";
import { API_URL } from "@/firebaseConfig";

export default defineComponent({
  components: { ActivityButton },
  data() {
    return { weatherData: { } as WeatherData | null };
  },
  async mounted() {
    const weatherData = await (
      await fetch(
        `${API_URL}/currentWeather`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      )
    ).json();
    const data = weatherData.sensors[0].data[0];
    this.weatherData = WeatherData.fromRaw(data);
  },
  methods: {
    openWeatherPage() {
      this.$router.push({ path: "weatherStation" });
    },
    actualWeather() {
      if (this.weatherData) {
        if (this.weatherData.solarRad > 0) {
          return { icon: "wb_sunny", descr: "Sole" };
        }
        if (this.weatherData.rain) {
          return { icon: "flood", descr: "Piove" };
        }
        if (
          this.weatherData.solarRad ||
          (0 === 0 && this.weatherData.rain === 0)
        ) {
          return { icon: "wb_cloudy", descr: "Nuvoloso" };
        }
        if (this.weatherData.windSpeed > 0) {
          return { icon: "wind_power", descr: "Mena il vento" };
        }
        if (this.weatherData.temp > 22) {
          return { icon: "local_fire_department", descr: "Fa caldo" };
        }
        return { icon: "wb_sunny", descr: "Sole" };
      }
    },
    formattedWeather() {
      if (!this.weatherData) {
        return "";
      }
      const windArrow = `<span class="material-icon" style="transform: rotate(${this.weatherData.windDir}deg); padding: 2px">north</span>`;
      return `
        <div><b>Temperatura</b>: ${this.weatherData.temp} °C</div>
        <div><b>Umidità</b>: ${this.weatherData.hum}%</div>
        <div><b>Pressione</b>: ${this.weatherData.bar} Pa (in ${
        this.weatherData.barTrend > 0 ? "aumento" : "diminuzione"
      })</div>
        <div style="display:flex;align-items:center;"><b>Vento</b>:${windArrow} ${
        this.weatherData.windSpeed
      } km/h</div>
      `;
    },
  },
});
