import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c875b76"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["moving"]
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "notification",
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", {
      class: "notification",
      onTouchmovePassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTouchMove && _ctx.onTouchMove(...args))),
      onTouchstartPassive: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTouchStart && _ctx.onTouchStart(...args))),
      onTouchendPassive: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTouchEnd && _ctx.onTouchEnd(...args))),
      style: _normalizeStyle({ transform: `translateX(${_ctx.translate}px)` }),
      moving: _ctx.moving
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message.notification.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message.notification.body), 1)
    ], 44, _hoisted_1)
  ]))
}