import { getMessaging } from 'firebase/messaging'
import { createApp } from 'vue'
import App from './App.vue'
import { clickOutsideDirective } from './directives/click-outside'
import './registerServiceWorker'
import router from './router'
import { key, store } from './store'
import { SET_VERSION } from './store/modules/settings'
import { strings } from './strings'

const app = createApp(App).use(store, key).use(router).directive('click-outside', clickOutsideDirective);
const t = strings;
const messaging = getMessaging();
app.config.globalProperties.$t = t;
app.config.globalProperties.$store = store; // TODO - Mi sa che non serve
app.mount('#app');

console.log('v' + process.env.VUE_APP_VERSION);
store.commit(SET_VERSION, process.env.VUE_APP_VERSION );

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $t: typeof t;
        $store: typeof store;
        $messaging: typeof messaging;
    }
}