
import { Options, Vue } from "vue-class-component";
import UseCarButton from "@/components/UseCarButton.vue";
import LeaveCarButton from "@/components/LeaveCarButton.vue";
import AdminButton from "@/components/AdminButton.vue";
import WeatherButton from "@/components/WeatherButton.vue";
import AlarmCard from "@/components/AlarmCard.vue";
import Settings from "@/components/Settings.vue";
import { useStore } from "vuex";
import { key, store } from "@/store";
import { LISTEN_CARS, LISTEN_FIRES } from "@/store/modules/appState";
import { FireReport } from "@/model/FireReport";

@Options({
  components: {
    UseCarButton,
    LeaveCarButton,
    AdminButton,
    WeatherButton,
    AlarmCard,
    Settings,
  },

  mounted() {
    // if (this.hasPermission("usecar")) { //TODO !!!
    store.dispatch(LISTEN_CARS);
    // }

    if (this.hasPermission("fire")) {
      store.dispatch(LISTEN_FIRES);
    }
  },
  computed: {
    displayName() {
      return useStore(key).state.firebase.user?.displayName;
    },
    usingCar: () => store.state.appState.user?.usingCar,
    usecar() {
      return this.hasPermission("usecar");
    },
    headerString() {
      const usedCar = store.getters.usedCar;
      if (usedCar) {
        return `Come va la ${usedCar.model}?`;
      }
      return `Ciao${this.displayName ? ", " + this.displayName : ""}!`;
    },
    user: () => store.state.appState.user,
    fires(): FireReport[] {
      if (this.$store.state.appState.fires) {
        return this.$store.state.appState.fires.filter(
          (f: FireReport) => f.closingTime == null
        );
      }
      return [];
    },
  },
  methods: {
    hasPermission(permission: string) {
      const user = store.state.appState.user;
      if (user) {
        return user.permissions[permission] != null;
      }
      return false;
    },
  },
  watch: {
    user(_newUser, oldUser) {
      if (oldUser == null) {
        if (this.hasPermission("fire")) {
          store.dispatch(LISTEN_FIRES);
        }
      }
    },
  },
})
export default class Home extends Vue {}
