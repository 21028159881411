<template>
  <div :class="{ activity_card: true, animated }" @click="expand">
    <div class="icon">
      <slot name="icon"></slot>
    </div>
    <div class="text">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="desciption">
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    return { animated: false };
  },
  methods: {
    expand() {
      this.animated = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.activity_card {
  @include card(2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  transition: transform 1s ease;
  transition-delay: 0.1s;
  .icon,
  .text {
    transition: opacity 0.2s ease;
  }

  &.animated {
    overflow: hidden;
    // $fx: 1.3;
    // $fy: 16;
    // $r: 8px;
    // border-radius: #{$r/$fx}/ #{$r/$fy};
    // transform: scale($fx, $fy);
    border-radius: 1%;
    transform: scale(1.3, 16);
    @media screen and (min-width: 600px) {
      transform: scale(10, 50);
    }

    z-index: 20;

    .icon,
    .text {
      opacity: 0;
    }
  }
}

.icon {
  margin-right: 6px;
  display: flex;

  svg {
    fill: color(on-surface);
  }
}

.text {
  text-align: initial;

  .title {
    font-weight: 500;
    font-size: 21px;
  }
}
</style>