import { Module } from "vuex";
import { RootState } from "..";

export interface SettingsVuex {
  installEvent: (Event & { prompt: () => void }) | null;
  updateAvailable: boolean;
  version: string | null;
  notificationsGranted: boolean;
}

export const settingsModule: Module<SettingsVuex, RootState> = {
  state: {
    installEvent: null,
    updateAvailable: false,
    version: null,
    notificationsGranted: Notification.permission === 'granted',
  },
  mutations: {
    setInstallEvent(state, installEvent) {
      state.installEvent = installEvent;
    },
    setUpdateAvailable(state, updateAvailable = true) {
      state.updateAvailable = updateAvailable;
    },
    setVersion(state, version) {
      state.version = version;
    },
    setNotificationsGranted(state, granted = Notification.permission === 'granted') {
      state.notificationsGranted = granted;
    },
  },
  actions: {
    //
  },
};

export const
  SET_INSTALL_EVENT = "setInstallEvent",
  SET_UPDATE_AVAILABLE = "setUpdateAvailable",
  SET_VERSION = "setVersion",
  SET_NOTIFICATION_GRANTED = "setNotificationsGranted";
