
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    const markerColor = getComputedStyle(document.querySelector("body")!)
      .getPropertyValue("--md-sys-color-error")
      .trim()
      .slice(1);
    return {
      mapType: this.darkMode() ? "dark-v10" : "outdoors-v11",
      markerColor,
    }; //light-v10, dark-v10, outdoors-v11
  },
  props: {
    title: { type: String, required: true },
    date: { type: String, required: true },
    time: { type: String, required: true },
    squadsNeeded: { type: Number, required: true },
    operators: { type: Number, required: true },
    foot: { type: String, required: false },
    lat: { type: Number, required: true },
    lng: { type: Number, required: true },
    zoom: { type: Number, required: false, default: 13 },
  },
  methods: {
    darkMode(): boolean {
      return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    },
  },
});
