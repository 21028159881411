import { Module } from "vuex";
import { RootState } from "..";

export interface ListenersVuex {
    user: boolean,
    cars: boolean,
    fires: boolean
}

export const listenersModule: Module<ListenersVuex, RootState> = {
    state: {
        user: false,
        cars: false,
        fires: false
    },
    mutations: {
        toggleListener(state, listener: 'user' | 'cars' | 'fires') {
            state[listener] = !state[listener];
        }
    },
}

export const
    TOGGLE_LISTENER = 'toggleListener'
