export class WeatherData {
    temp: number;
    hum: number;
    bar: number;
    barTrend: number;
    rain: number;
    rainDay: number;
    rainMonth: number;
    rainYear: number;
    windDir: number;
    windSpeed: number;
    windSpeedAvg: number;
    windChill: number;
    dewPoint: number;
    heatIndex: number;
    solarRad: number;
    uv: number;
    forecastCode: number;
    timestamp: number;
    
    constructor(obj: WeatherData) {
        this.temp = obj.temp;
        this.hum = obj.hum;
        this.bar = obj.bar;
        this.barTrend = obj.barTrend;
        this.rain = obj.rain;
        this.rainDay = obj.rainDay;
        this.rainMonth = obj.rainMonth;
        this.rainYear = obj.rainYear;
        this.windDir = obj.windDir;
        this.windSpeed = obj.windSpeed;
        this.windSpeedAvg = obj.windSpeedAvg;
        this.windChill = obj.windChill;
        this.dewPoint = obj.dewPoint;
        this.heatIndex = obj.heatIndex;
        this.solarRad = obj.solarRad;
        this.uv = obj.uv;
        this.forecastCode = obj.forecastCode;
        this.timestamp = obj.timestamp;
    }

    static fromRaw(data: { [key: string]: number }) {
        return {
            temp: this.toCelsius(data.temp_out),
            hum: data.hum_out,
            bar: this.inchOfMtoPascal(data.bar),
            barTrend: data.bar_trend,
            rainDay: data.rain_day_mm,
            rainMonth: data.rain_month_mm,
            rainYear: data.rain_year_mm,
            rain: data.rain_rate_mm,
            windDir: data.wind_dir,
            windSpeed: this.mphToKmh(data.wind_speed),
            windSpeedAvg: this.mphToKmh(data.wind_speed_10_min_avg),
            windChill: this.toCelsius(data.wind_chill),
            dewPoint: this.toCelsius(data.dew_point),
            heatIndex: this.toCelsius(data.heat_index),
            solarRad: data.solar_rad,
            uv: data.uv,
            forecastCode: data.forecast_rule,
            timestamp: data.ts * 1000,
        };
    }

    static toCelsius(f: number) {
        return parseFloat(((f - 32) / 1.8).toFixed(2));
    }
    static inchOfMtoPascal(i: number) {
        return parseFloat((i * 3386.3886666667).toPrecision(6));
    }
    static mphToKmh(mph: number) {
        return parseFloat((mph * 1.609344).toFixed(1));
    }
}