import { Directive, DirectiveBinding, VNode } from "vue"

export const clickOutsideDirective: Directive = {
    beforeMount (el: any, binding: DirectiveBinding, vnode: VNode) {
        el.clickOutsideEvent = function (event: MouseEvent) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
}