import { firebaseConfig, onLocal } from "@/firebaseConfig";
import { FirebaseApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";

export class FirebaseService {
    private static instance: FirebaseService;
    app: FirebaseApp;

    private constructor() {
        this.app = initializeApp(firebaseConfig);
        const auth = getAuth();
        const database = getDatabase();
        if (onLocal) {
            connectAuthEmulator(auth, "http://localhost:9099");
            connectDatabaseEmulator(database, "localhost", 9000);
        }
    }

    static getInstance() {
        if (!FirebaseService.instance) {
            FirebaseService.instance = new this;
        }
        return FirebaseService.instance;
    }

    static getApp() {
        return FirebaseService.getInstance().app;
    }
}