export class GeolocationService {

    static geoWatcher: number;

    static useGeolocation(onPosition: (position: GeolocationPosition) => void, options?: PositionOptions) {
        if ("navigator" in window && "geolocation" in navigator) {
            this.geoWatcher = navigator.geolocation.watchPosition((position) => {
                onPosition(position);
            }, null, options);
        }
    }

    static stopWatchPosition() {
        if (this.geoWatcher) {
            navigator.geolocation.clearWatch(this.geoWatcher);
        }
    }
}