import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d0d4e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "alarm-card" }
const _hoisted_2 = { class: "img-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-content" }
const _hoisted_5 = { class: "headline4" }
const _hoisted_6 = { class: "body1" }
const _hoisted_7 = { class: "body1" }
const _hoisted_8 = { class: "body1" }
const _hoisted_9 = { class: "body1" }
const _hoisted_10 = { class: "body1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `https://api.mapbox.com/styles/v1/mapbox/${_ctx.mapType}/static/pin-s+${_ctx.markerColor}(${_ctx.lng},${_ctx.lat})/${_ctx.lng},${_ctx.lat},${_ctx.zoom},0,50/200x200?access_token=pk.eyJ1Ijoid29sb2xvb28iLCJhIjoiY2wydDNsejB1MDBrMTNjbzF3OGp1M3Q3eCJ9.LThv7eR2fd4MokzhGrivIQ`
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t.date) + ": " + _toDisplayString(_ctx.date), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t.time) + ": " + _toDisplayString(_ctx.time), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t.squadsNeeded) + ": " + _toDisplayString(_ctx.operators) + "/" + _toDisplayString(_ctx.squadsNeeded * 4), 1),
      _createElementVNode("div", _hoisted_9, "Lat: " + _toDisplayString(_ctx.lat.toFixed(4)), 1),
      _createElementVNode("div", _hoisted_10, "Lon: " + _toDisplayString(_ctx.lng.toFixed(4)), 1)
    ])
  ]))
}