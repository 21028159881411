import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex'
import { appStateModule, AppStateVuex } from './modules/appState';
import { FirebaseVuex, firebaseModule } from './modules/firebaseModule';
import { listenersModule, ListenersVuex } from './modules/listeners';
import { messagesModule, MessagesVuex, } from './modules/messagesModule';
import { settingsModule, SettingsVuex } from './modules/settings';

export interface RootState {
  firebase: FirebaseVuex,
  appState: AppStateVuex,
  messages: MessagesVuex,
  settings: SettingsVuex,
  listeners: ListenersVuex
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  modules: {
    firebase: firebaseModule,
    appState: appStateModule,
    messages: messagesModule,
    settings: settingsModule,
    listeners: listenersModule,
  }
})
