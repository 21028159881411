import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "notif" }, {
      default: _withCtx(() => [
        (_ctx.message)
          ? (_openBlock(), _createBlock(_component_Notification, {
              key: 0,
              message: _ctx.message,
              onDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.message = null))
            }, null, 8, ["message"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          name: route.meta.transition || 'fade',
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    })
  ], 64))
}