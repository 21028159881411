import { vapidKey } from "@/firebaseConfig";
import { getMessaging, getToken } from "firebase/messaging";
import { FirebaseDBService } from "./FirebaseDBService";
import { User } from "@/model/User";
import { FirebaseError } from "firebase/app";
import { store } from "@/store";
import { SET_NOTIFICATION_GRANTED } from "@/store/modules/settings";

export class UserService {

    private db: FirebaseDBService;
    // private store: Store<RootState>;

    constructor() {
        this.db = new FirebaseDBService();
        // this.store = store;
    }

    async saveNoticationToken(token: string, userUid: string) {
        const updates = {
            [`/users/${userUid}/notificationTokens/${token}`]: true
        }
        try {
            await this.db.updateValues(updates);
        } catch (error) {
            console.error(error);
        }
    }


    async getMessagingToken() {
        try {
            const messaging = getMessaging();
            const currentToken = await getToken(messaging, {
                vapidKey: vapidKey,
            });
            if (currentToken) {
                // console.log("client token: ", currentToken);
                return currentToken;
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
            }
        } catch (error) {
            if (error instanceof FirebaseError) {
                if (error.code === 'messaging/permission-blocked') {
                    // update store.state.settings.notificationGranted  ?
                    store.commit(SET_NOTIFICATION_GRANTED);
                }
            } else {
                console.error("An error occurred while retrieving token. ", error);
            }
        }
    }

    async saveUserInfo(userUid: string, info: User["info"]) {
        const updates = {
            [`/users/${userUid}/info`]: info
        }
        try {
            await this.db.updateValues(updates);
        } catch (error) {
            console.error(error);
        }
    }

}
