import { store } from '../store';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import { User } from '@/model/User';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requireLogin: true, statusColor: "primary-container" }
  },
  {
    path: '/no-permission',
    name: 'No Permission',
    component: () => import('../views/NoPermission.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/usecar',
    name: 'Use car',
    component: () => import('../views/UseCar.vue'),
    meta: { transition: 'expand-card', requireLogin: true },
  },
  {
    path: '/leavecar',
    name: 'Leave car',
    component: () => import('../views/LeaveCar.vue'),
    meta: { transition: 'expand-card', requireLogin: true },
  },
  {
    path: '/weatherStation',
    name: 'Weather Station',
    component: () => import('../views/WeatherStation.vue'),
    meta: { transition: 'expand-card', requireLogin: true },
  },
  {
    path: '/grantNotifications',
    name: 'Grant notifications',
    component: () => import('../views/GrantNotifications.vue'),
    meta: { transition: 'slide', requireLogin: true },
  },
  {
    path: '/fireReport/:id',
    component: () => import('../views/FireReport.vue'),
    meta: { transition: 'expand-card', requireLogin: true },
  },
  {
    path: '/fireReport/:id/close',
    component: () => import('../views/CloseFireReport.vue'),
    meta: { transition: 'expand-card', requireLogin: true, permissions: ["fire_lead"] },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: { transition: 'expand-card', requireLogin: true },
    children: [
      { path: '', component: () => import('../views/AdminHome.vue'), meta: { transition: 'expand-card', requireLogin: true } },
      // { path: 'notifications', component: () => import('../views/AdminNotifications.vue'), meta: { transition: 'slide', requireLogin: true } },
      { path: 'report-fire', component: () => import('../views/ReportFire.vue'), meta: { transition: 'slide', requireLogin: false } },
      { path: 'notifications/newTopic', component: () => import('../views/NewTopic.vue'), meta: { transition: 'slide', requireLogin: true } },
    ]
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  // Status bar color
  const color = getComputedStyle(document.querySelector("body")!)
    .getPropertyValue(to.meta.statusColor ? `--md-sys-color-${(to.meta.statusColor as string)}` : '--md-sys-color-surface');
  const existingTag = document.getElementsByName('theme-color');
  const tag = existingTag[0] ? existingTag[0] : document.createElement('meta');
  tag.setAttribute('name', 'theme-color');
  tag.setAttribute('content', color.trim());
  document.head.appendChild(tag);

  if (to.meta.requireLogin && !store.state.firebase.user) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: {
        redirect: to.fullPath
      }
    }
  }

  if (to.meta.permissions) {
    if (!store.state.firebase.user || !store.state.appState.user) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      }
    }
    if (!store.getters.userHasPermissions(to.meta.permissions as string[])) {
      return {
        path: '/no-permission'
      }
    }
  }
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length;
  const fromDepth = from.path.split('/').length;
  if (to.meta.transition === 'slide') {
    to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
  } else if (toDepth !== fromDepth && (from.meta.transition === 'slide-right' || from.meta.transition === 'slide-left')) {
    to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    // to.meta.transition = from.meta.transition;
  }
})

export default router
