import { Car } from "@/model/Car";
import { FireReport } from "@/model/FireReport";
import { User } from "@/model/User";
import { FirebaseDBService } from "@/services/FirebaseDBService";
import { UserService } from "@/services/UserService";
import { Module } from "vuex";
import { RootState } from "..";
import { TOGGLE_LISTENER } from "./listeners";
import { LISTEN_MESSAGES } from "./messagesModule";

export interface AppStateVuex {
  fires: FireReport[] | null;
  cars: Car[] | null;
  user: User | null;
  finger: boolean;
  position: GeolocationPosition | null;
}

const firebase = new FirebaseDBService();

export const appStateModule: Module<AppStateVuex, RootState> = {
  state: {
    fires: null,
    cars: null,
    user: null,
    finger: false,
    position: null,
  },
  mutations: {
    updateCars(state, cars) {
      state.cars = cars;
    },
    updateFires(state, reports) {
      state.fires = reports;
    },
    updateUser(state, user) {
      state.user = { ...user };
    },
    setFinger(state, finger = !state.finger) {
      state.finger = finger;
    },
    updatePosition(state, position) {
      state.position = position;
    },
  },
  actions: {
    listenFires: ({ commit, rootState }) => {
      if (!rootState.listeners.fires) {
        firebase.listenForChanges<{ [id: string]: FireReport }>("reports/fire", (reports) => {
          if (reports) {
            commit("updateFires", Object.values(reports || {}))
          }
        });
        commit(TOGGLE_LISTENER, 'fires');
      }
    },
    listenCars: ({ commit, rootState }) => {
      if (!rootState.listeners.cars) {
        firebase.listenForChanges<{ [id: string]: Car }>("cars", (cars) =>
          commit("updateCars", Object.values(cars || {}))
        );
        commit(TOGGLE_LISTENER, 'cars');
      }
    },
    listenUser: async ({ commit, rootState, dispatch }) => {
      dispatch("initUser");
      if (!rootState.listeners.user) {
        firebase.listenForChanges<User>(
          `users/${rootState.firebase.user?.uid}`,
          (user) => commit("updateUser", user)
        );
        commit(TOGGLE_LISTENER, 'user');
      }
    },
    initUser: async ({ commit, rootState, dispatch }) => {
      const authUser = rootState.firebase.user;

      if (authUser) {
        const uid = authUser.uid;
        const user = await firebase.readData<User>(
          `users/${rootState.firebase.user?.uid}`
        );
        if (user) {
          commit("updateUser", user);
          const userService = new UserService();
          const currentToken = await userService.getMessagingToken();
          dispatch(LISTEN_MESSAGES);
          if (
            currentToken &&
            !(
              user.notificationTokens &&
              Object.keys(user.notificationTokens).includes(currentToken)
            )
          ) {
            await userService.saveNoticationToken(currentToken, uid);
          }
          if (
            authUser.displayName &&
            authUser.email &&
            (!user.info ||
              (user.info.name !== authUser.displayName &&
                user.info.mail !== authUser.email))
          ) {
            await userService.saveUserInfo(uid, {
              name: authUser.displayName,
              mail: authUser.email,
            });
          }
        }
      } else {
        // dispatch error 'Utente non autenticato'
      }
    },
  },
  getters: {
    carByPlate: (state) => (plate: string) => {
      if (state.cars) {
        return state.cars.find((c) => c.plate === plate);
      }
      return null;
    },
    usedCar: (state) => {
      if (state.cars && state.user) {
        return state.cars.find((c) => c.plate === state.user?.car);
      }
      return null;
    },
    permissions: (state) => {
      if (state.user) {
        return Object.keys(state.user.permissions);
      }
      return [];
    },
    userHasPermissions: (state) => (permissions: string[]): boolean => {
      const user = state.user;
      if (!user) {
        return false;
      }

      /* WITH PATH
      for (const permission of permissions) {
        const path = permission.split('/');
        let userPerm = user.permissions ;
        for (const perm of path) {
          userPerm = userPerm[perm] as PermissionType;
          if (userPerm == null) {
            return false;
          }
        }
      }
      return true; 
       */
      for (const permission of permissions) {
        if (user.permissions[permission] == null) {
          return false;
        }
      }
      return true;
    },
    notificationTokens: (state) => {
      if (state.user && state.user.notificationTokens) {
        return Object.keys(state.user.notificationTokens);
      }
      return [];
    },
    fireById: (state) => (id: string) => {
      if (state.fires) {
        return state.fires.find((f) => f.id === id);
      }
      return null;
    },
  },
};

export const LISTEN_CARS = "listenCars",
  LISTEN_FIRES = "listenFires",
  LISTEN_USER = "listenUser",
  INIT_USER = "initUser",
  UPDATE_POSITION = "updatePosition",
  SET_FINGER = "setFinger";
