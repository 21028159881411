
/* import {
  CHANGE_MAX_BOMB_TIME,
  CHANGE_MIN_BOMB_TIME,
  TOGGLE_FONT,
  TOGGLE_FULL_SCREEN,
} from "@/store/modules/SettingsModule"; */
import { INIT_USER } from "@/store/modules/appState";
import { getAuth, signOut } from "@firebase/auth";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    const showMenu = false;
    return { showMenu };
  },
  methods: {
    onClickOutside(/* _e: MouseEvent, _el: Event */) {
      this.showMenu = false;
    },
    logout() {
      signOut(getAuth()); //TODO - Fare logout altrove?
      this.$router.push("login");
    },
    installApp() {
      if (this.installEv) {
        this.installEv.prompt();
      }
    },
    updateApp() {
      if (this.updateAvailable) {
        window.location.reload();
      }
    },
    goToGrantNotifications() {
      if (!this.notificationEn) {
        this.$router.push("/grantNotifications");
        // this.$store.dispatch(INIT_USER);
      } else {
        //
      }
    },
  },
  computed: {
    installEv() {
      return this.$store.state.settings.installEvent;
    },
    updateAvailable() {
      return this.$store.state.settings.updateAvailable;
    },
    version() {
      return this.$store.state.settings.version;
    },
    notificationEn() {
      return this.$store.state.settings.notificationsGranted; // Notification.permission === 'granted' && this.$store.getters.notificationTokens.length > 0;
    },
  },
});
