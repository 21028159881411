<template>
  <ActivityButton @click="openUseCarPage">
    <template #icon>
      <span class="material-icon">admin_panel_settings</span>
    </template>
    <template #title> {{ $t.adminTitle }} </template>
    <template #description> {{ $t.adminDesc }} </template>
  </ActivityButton>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import ActivityButton from "./ActivityButton.vue";

export default defineComponent({
  components: { ActivityButton },
  methods: {
    openUseCarPage() {
      this.$router.push({ path: "admin" });
    },
  },
});
</script>

<style lang="scss" scoped>
span {
  font-size: 36px;
  color: color(on-surface);
}
</style>