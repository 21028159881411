
import { Message } from "@/model/FcmMessage";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  emits: ["dismiss"],
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  mounted() {
    if (window) {
      this.windowWidth = window.innerWidth;
      window.addEventListener(
        "resize",
        (event) => (this.windowWidth = window.innerWidth)
      );
    }
  },
  data() {
    return {
      touchStartPoint: [0, 0],
      translate: 0,
      moving: false,
      windowWidth: 0,
    };
  },
  methods: {
    onTouchMove(event: TouchEvent) {
      this.translate =
        event.changedTouches[0].screenX - this.touchStartPoint[0];
    },
    onTouchStart(event: TouchEvent) {
      this.moving = false;

      this.touchStartPoint = [
        event.changedTouches[0].screenX,
        event.changedTouches[0].screenY,
      ];
    },
    onTouchEnd(event: TouchEvent) {
      this.moving = true;
      const maxSwipe = 160;
      if (this.translate > maxSwipe) {
        this.translate = this.windowWidth + 1;
        this.$emit("dismiss");
      } else if (this.translate < -maxSwipe) {
        this.translate = -(this.windowWidth + 1);
        this.$emit("dismiss");
      } else {
        this.translate = 0;
      }
    },
    onClick() {
      if (this.message.data && this.message.data.path) {
        this.$router.push(this.message.data.path);
        this.$emit("dismiss");
      }
    },
  },
});
