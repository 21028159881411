
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import { FirebaseService } from "./services/FirebaseService";
import { key } from "./store";
import { SET_INSTALL_EVENT } from "./store/modules/settings";
import { LISTEN_USER_AUTH } from "./store/modules/firebaseModule";
import Notification from "@/components/Notification.vue";
import { MessagePayload } from "@firebase/messaging";
import { GeolocationService } from "@/services/GeolocationService";
import { UPDATE_POSITION } from "./store/modules/appState";

export default defineComponent({
  components: {
    Notification,
  },
  data() {
    let message: MessagePayload | any = null;
    return { message };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (ev) => {
      this.$store.commit(SET_INSTALL_EVENT, ev);
    });
    const app = FirebaseService.getApp();
    useStore(key).dispatch(LISTEN_USER_AUTH);
  },
  async mounted() {
    GeolocationService.useGeolocation((position) => this.$store.commit(UPDATE_POSITION, position));
  },
  unmounted() {
    GeolocationService.stopWatchPosition();
  },
  methods: {
    //
  },
  /* computed: {
    messages() {
      return this.$store.state.messages.messages;
    },
  }, */
  watch: {
    "$store.state.messages.lastMessage"(nv: MessagePayload) {
      this.message = nv;
      setTimeout(() => {
        this.message = null;
      }, 10000);
    },
  },
});
