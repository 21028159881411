import { FirebaseApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Module } from "vuex";
import { RootState } from "..";
import { loadObjFromLocal, withSaveObjLocal } from "../utils";
import { LISTEN_USER } from "./appState";

export interface FirebaseVuex {
    app: FirebaseApp | null,
    user: User | null,
}

export const firebaseModule: Module<FirebaseVuex, RootState> = {
    state: {
        app: null,
        user: loadObjFromLocal<User | null>('user', null),
    },
    mutations: {
        setApp(state, app) {
            state.app = app;
        },
        setUser: withSaveObjLocal<FirebaseVuex>('user', (state, user) => {
            state.user = user;
        })
    },
    actions: {
        listenUserAuth: ({ commit, dispatch }) => {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    commit(SET_USER, user);
                    dispatch(LISTEN_USER);
                } else {
                    commit(SET_USER, null);
                }
            });
        },
    },
    getters: {
        uid: (state) => {
            if (state.user && state.user.uid) {
                return state.user.uid;
            }
            return null;
        },
    }
}

export const
    SET_APP = 'setApp',
    SET_USER = 'setUser',
    LISTEN_USER_AUTH = 'listenUserAuth'
