import { Mutation } from "vuex";

//TODO - Da migliorare (property deve essere il nome della proprietà!!!!!)
function withSaveObjLocal<T>(property: string, mutation: Mutation<T>): Mutation<T> {
    const oldMutation = mutation;
    const newMutation: Mutation<T> = (...[state, payload]) => {
        oldMutation(state, payload);
        const stringObj = JSON.stringify((state as any)[property]);//Not good (as any)
        localStorage.setItem(property, stringObj);
    }
    return newMutation;
}
function withSaveStringLocal<T>(property: string, mutation: Mutation<T>): Mutation<T> {
    const oldMutation = mutation;
    const newMutation: Mutation<T> = (...[state, payload]) => {
        oldMutation(state, payload);
        localStorage.setItem(property, (state as any)[property]); //Not good (as any)
    }
    return newMutation;
}

function loadObjFromLocal<T>(key: string, ifNotPresent: T): T {
    const local = JSON.parse(localStorage.getItem(key)!) as T;
    return local ? local : ifNotPresent;

}
function loadStringFromLocal(key: string, ifNotPresent: string): string {
    const local = localStorage.getItem(key);
    return local ? local : ifNotPresent;
}

export { withSaveObjLocal, withSaveStringLocal, loadObjFromLocal, loadStringFromLocal }