export const strings = {
    useCarTitle: "Utilizza parco auto",
    useCarDesc: "Registra gli spostamenti dei mezzi dell'associazione",
    chooseCar: "Scegli mezzo",
    causal: "Motivo dello spostamento",
    startOdometer: "Contachilometri alla partenza",
    useCarBtn: "Prendi",

    leaveCarTitle: "Lascia macchina",
    leaveCarDesc: "Chiudi e registra report spostamento",
    stopOdometer: "Contachilometri finale",
    declarations: "Qualcosa da dichiarare?",
    leaveCarBtn: "Lascia",

    adminTitle: "Pannello di amministrazione",
    adminDesc: "Gestione riservata al direttivo",
    csvDownloadDesc: "Non puoi ancora fare granché ma per il momento puoi scaricare il csv dei report",
    csvDownloadBtn: "Scarica CSV",

    noPermission: "Sembra che tu non abbia alcun permesso. Chiedi ad uno degli amministratori di configurare il tuo profilo o effettua il logout (menú in alto a destra)",

    loginDesc: "Per usare quest'app devi essere loggato. Per loggarti devi avere un account Google. Verrano presi dal tuo account solo nome ed indirizzo mail per la gestione dei permessi",
    loginTitle: "Protezione civile gruppo lucano Tricarico",
    loginWithGoogle: "Accedi con Google",
    logout: "Effettua logout",
    install: "Installa app",
    update: "Aggiorna app",
    notificationDesc: "Invia una notifica ad un gruppo di iscritti",
    goToAdminPush: "Invia notifica",
    sendNotification: "Invia",
    sendingNotification: "Inviando...",
    title: "Titolo",
    description: "Description",
    users: "Utenti",
    topic: "Topic",
    newTopic: "Crea topic",
    moreSecureDescription: "Per accedere a questa sezione è necessario un maggiore livello di sicurezza. Usa la tua impronta o la tua fede per accedere",
    moreSecureButton: "Verifica",
    checking: "Un secondo...",
    enableNotifications: "Attiva notifiche",
    grantedNotificationsDesc: "Le notifiche su quest'app sono attive ",
    enableNotificationsDesc: "Sembra tu non abbia attivato le notifiche (o potrebbero essere state disattivate in automatico). Quest'app usa le notifiche per avvisarti nel caso siano necessari interventi a cui puoi prendere parte. Apri le impostazioni del sito (probabilmente cliccando sul lucchetto vicino l'url qui in alto) e attiva le notifiche. <div><b>Dopo averlo fatto ricorda di aggiornare la pagina.</b></div> <div class='link'><a href='https://support.google.com/chrome/answer/3220216?hl=it&co=GENIE.Platform%3DAndroid#:~:text=Consentire%20o%20bloccare%20le%20notifiche%20di%20un%20sito%20specifico'>Questo link potrebbe esserti utile</a></div> ",
    backToHome: "Torna alla home",

    //TODO - Aggiungere stringhe stazione meteo!!

    reportFire: "Avvia report incendio",
    fireDesc: "Avvia un'operazione per un incendio",
    goToFire: "Vai va'",
    position: "Posizione",
    latitude: "Latitudine",
    longitude: "Longitudine",
    squadNumber: "Numero squadre",
    additionalInfo: "Informazioni aggiuntive",
    squad: "squadra",
    squads: "squadre",
    openReport: "Apri report",
    date: "Data",
    time: "Ora",
    squadsNeeded: "Operatori",
    participate: "Partecipa",
    leave: "Lascia operazione",
    directions: "Indicazioni",
    fireInfo: "Informazioni incendio",
    alreadyIn: "Stai partecipando a questa operazione",
    closeReport: "Chiudi report",
    closeInfo: "Informazioni chiusura",
    close: "Chiudi",
}