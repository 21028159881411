import { getMessaging, MessagePayload, onMessage } from "firebase/messaging";
import { Module } from "vuex";
import { RootState } from "..";

export interface MessagesVuex {
    messages: MessagePayload[];
    lastMessage: MessagePayload | null;
}


export const messagesModule: Module<MessagesVuex, RootState> = {
    state: {
        messages: [],
        lastMessage: null
    },
    mutations: {
        addMessage(state, message) {
            state.messages.push(message);
        },
        updateLastMessage(state, message) {
            state.lastMessage = message;
        },
    },
    actions: {
        listenMessages: async ({ commit }) => {
            onMessage(getMessaging(), (m) => {
                console.log(m);
                commit('addMessage', m);
                commit('updateLastMessage', m);
            });
        }
    },
    getters: {

    }
}

export const
    LISTEN_MESSAGES = 'listenMessages'